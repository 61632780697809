<template>
  <div class="staff-details">
    <!-- Staff Information -->
    <StaffInformation
      v-if="$user.isEditor()"
      :contact="contact"
      class="mb-4"
    />

    <!-- Staff Credit -->
    <StaffCredit
      :contact="contact"
      class="mb-4"
    />

    <!-- Staff Page -->
    <StaffPage
      :contact="contact"
      class="mb-4"
    />
  </div>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('staff')

// Components
import StaffCredit from '@/components/Staff/StaffCredit'
import StaffInformation from '@/components/Staff/StaffInformation'
import StaffPage from '@/components/Staff/StaffPage'

export default {
  name: 'StaffDetails',
  // -------------
  // Components ==
  // -------------
  components: {
    StaffCredit,
    StaffInformation,
    StaffPage
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'contact'
    ])
  }
}
</script>
