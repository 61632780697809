<template>

  <!-- Staff Credit -->
  <j-card
    :title="cardTitle"
    class="staff-credit"
  >
    <j-card-text class="pa-3">
      <v-container
        fluid
        class="pa-0"
      >
        <v-row no-gutters>
          <v-col
            cols="2"
            class="mr-4"
          >

            <!-- Badge -->
            <j-avatar
              :source="contact.badge_src"
              :alt="contact.initials"
              class="show-pointer"
              @click.native="handlePortrait"
            />
          </v-col>
          <v-col>

            <!-- Actions -->
            <slot name="actions" />

            <!-- Title -->
            <h2 class="headline mb-2">
              {{ contact.getName }}
            </h2>

            <!-- Description -->
            <j-markdown
              :source="contact.brief_bio"
              class="description mb-1"
            />

          </v-col>
        </v-row>
      </v-container>
    </j-card-text>
  </j-card>
</template>

<script>
export default {
  name: 'StaffCredit',
  // -------------
  // Properties ==
  // -------------
  props: {
    contact: {
      type: Object,
      default: null
    },
    cardTitle: {
      type: String,
      default: 'Article Credit'
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Handlers
    handlePortrait () {
      this.$emit('portrait')
    }
  }
}
</script>

<style lang="scss">

.staff-credit {

  .headline {
    @extend %j-headline;
  }

  .description {
    @extend %j-description;
  }

}

</style>
