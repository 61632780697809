<template>
  <j-dialog
    :value="$dialog.status('disable-staff')"
    :title="confirmationTitle"
    width="50%"
    @cancel="handleCancelContactDisable"
  >
    <!-- Body -->
    <StaffCredit
      :contact="contact"
      class="ma-4"
    />

    <!-- Actions -->
    <template #actions>
      <!-- Delete -->
      <j-button
        :label="confirmationButtonText"
        :type="confirmationButtonType"
        :processing="contact.saving"
        @action="handleConfirmContactDisable"
      />
    </template>

    <!-- Options -->
    <template #options>
      <!-- Cancel -->
      <j-button
        v-if="!contact.saving"
        label="Cancel"
        type="option"
        @action="handleCancelContactDisable"
      />
    </template>
  </j-dialog>
</template>

<script>

// Components
import StaffCredit from '@/components/Staff/StaffCredit'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('staff')

export default {
  name: 'DisableStaffDialog',
  // -------------
  // Components ==
  // -------------
  components: {
    StaffCredit
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'contact'
    ]),
    confirmationTitle() {
      return this.contact.deleted_at ? 'Confirm restore staff...' : 'Confirm disable staff...'
    },
    confirmationButtonType() {
      return this.contact.deleted_at ? 'primary' : 'danger'
    },
    confirmationButtonText() {
      return this.contact.deleted_at ? 'Restore' : 'Disable'
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'confirmDeletion',
      'cancelDeletion',
      'disableContact'
    ]),
    handleConfirmContactDisable() {
      this.disableContact()
    },
    handleCancelContactDisable() {
      this.$dialog.close('disable-staff')
    }
  }
}
</script>
