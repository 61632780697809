<template>

  <!-- Staff Tools - Viewing -->
  <div v-if="showTools" class="staff-tools-viewing">
    <j-menu icon="cog" button-type="icon" >

      <!-- Edit -->
      <j-menu-item
        icon="edit"
        label="Edit Profile"
        v-if="userCanEdit"
        @click="handleEdit"
      />

      <!-- Alias -->
      <j-menu-item
        icon="eye"
        label="Log in as User"
        v-if="userCanAlias"
        @click="handleAlias"
      />

      <!-- Create Room 19 Account -->
      <j-menu-item
        v-if="!userHasRoom19Username"
        icon="user"
        label="Create Room 19 Account"
        @click="handleCreateRoom19Account"
      />

      <!-- Reset Password -->
      <j-menu-item
        icon="key"
        label="Reset Password"
        @click="handleReset"
      />

      <!-- Disable -->
      <j-menu-item
        icon="times"
        :label="disableLabel"
        v-if="userCanDisable"
        @click="handleDisable"
      />

    </j-menu>

    <!-- Staff Directory -->
    <j-button
      type="icon"
      help="Staff Directory"
      icon="list"
      @action="handleIndex"
    />

  </div>

  <!-- Staff Tools - Editing -->
  <div v-else-if="showActions" class="staff-tools-editing">

    <!-- Actions -->
    <j-actions
      :record="contact"
      @cancel="showView"
      @save="handleSave"
    />

  </div>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('staff')

export default {
  name: 'StaffTools',
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'contact'
    ]),
    isProfile() {
      return this.contact.isProfileFor(this.$user)
    },
    userCanEdit () {
      // Edit if the user is an admin, or if this is the user's profile
      return this.$user.isAdmin || this.isProfile
    },
    userCanAlias () {
      // Only admins, but you can't alias as yourself
      return this.$user.isAdmin && !this.isProfile
    },
    userHasMailbox () {
      return this.contact.mailbox
    },
    userHasRoom19Username () {
      return !!(this.contact.room19_username)
    },
    userCanDisable () {
      // Only admins, but you can't disable your own profile
      return this.$user.isAdmin && !this.isProfile
    },
    disableLabel () {
      return this.contact.deleted_at ? 'Restore' : 'Disable'
    },
    showTools () {
      return this.$route.name == 'contributor-view'
    },
    showActions () {
      return this.$route.name.startsWith('contributor-edit') || this.$route.name === 'staff-create'
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'saveContact'
    ]),
    showView () {
      if (this.$route.name === 'staff-create') {
        this.$router.push({ name: 'staff-directory' })
      } else {
        this.$router.push({ name: 'contributor-view', params: this.$route.params })
      }
    },
    handleIndex () {
      this.$router.push({ name: 'staff-directory' })
    },
    handleEdit () {
      this.$router.push({ name: 'contributor-edit', params: { slug: this.contact.slug }})
    },
    handleAlias () {
      this.$router.push({ name: 'alias', params: { slug: this.contact.slug }})
    },
    handleCreateRoom19Account () {
      this.$dialog.open('create-room19-account')
    },
    handleReset () {
      this.$dialog.open('password-reset')
    },
    handleDisable () {
      this.$dialog.open('disable-staff')
    },
    handleSave () {
      this.saveContact()
    }
  }
}
</script>
