<template>
  <j-dialog
    :value="$dialog.status('create-room19-account')"
    title="Create Room 19 Account"
    width="50%"
    @cancel="handleCancelCreateRoom19Account"
  >
    <!-- Body -->
    <j-card-text>

      <!-- Instructions -->
      <j-alert
        v-if="!accountCreated"
        type="info"
        message="Room 19 is Ink 19's own Mastodon server. To create an account, enter your desired username below. Letters, numbers, and underscores are allowed &mdash; no hyphens or spaces. Choose carefully, because once you have created your account, you will not be able to change the username."
        class="mt-4"
      />

      <!-- Success -->
      <j-alert
        v-else
        type="success"
        message="Your Room 19 account has been created! You will receive an email with login instructions, including a temporary password. If you are having problems with your account, please contact mastodon@ink19.com"
        class="mt-4"
      />

      <j-form 
        v-if="!accountCreated"
        model="staff.contact"
      >
        <!-- Room 19 Username -->
        <j-control
          name="room19_username"
          cols="12"
        />

      </j-form>
    </j-card-text>

    <!-- Actions -->
    <template #actions>
      <!-- Create -->
      <j-button
        v-if="!accountCreated"
        label="Create"
        type="primary"
        :disable="contact.pristine"
        :processing="contact.saving"
        @action="handleConfirmCreateRoom19Account"
      />
      <j-button
        v-else
        label="Close"
        type="option"
        @action="handleCancelCreateRoom19Account"
      />
    </template>

    <!-- Options -->
    <template v-if="!accountCreated" #options>

      <!-- Close -->
      <j-button
        v-if="!contact.saving"
        label="Cancel"
        type="option"
        @action="handleCancelCreateRoom19Account"
      />
    </template>
  </j-dialog>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('staff')

export default {
  name: 'CreateRoom19Account',
  data () {
    return {
      accountCreated: false
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'contact'
    ])
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'confirmDeletion',
      'cancelDeletion',
      'disableContact'
    ]),
    handleConfirmCreateRoom19Account() {
      this.contact.save().then(() => {
        this.accountCreated = true
      })
    },
    handleCancelCreateRoom19Account() {
      this.$dialog.close('create-room19-account')
    }
  }
}
</script>
