<template>

  <!-- Staff Record View -->
  <j-page
    :icon="contactIcon"
    :title="contact.name"
    :loading="loading"
    fill
    id="staff-record-view"
  >

    <!-- Actions -->
    <template #actions>

      <!-- Staff Tools -->
      <StaffTools />

    </template>

    <!-- Left - Details -->
    <template #left>

      <!-- Staff Details -->
      <StaffDetails />

    </template>

    <!-- Right - View / Edit -->
    <template #right>

      <!-- Router View -->
      <router-view />

    </template>

    <!-- Dialogs -->
    <template #dialogs>

      <!-- Password Reset -->
      <PasswordResetDialog />
  
      <!-- Confirm Staff Disable -->
      <DisableStaffDialog />

      <!-- Create Room 19 Account Dialog -->
      <CreateRoom19AccountDialog />

    </template>  
  </j-page>
</template>

<script>

// Components
import CreateRoom19AccountDialog from '@/components/Staff/CreateRoom19AccountDialog'
import DisableStaffDialog from '@/components/Staff/DisableStaffDialog'
import PasswordResetDialog from '@/components/Staff/PasswordResetDialog'
import StaffDetails from '@/components/Staff/StaffDetails'
import StaffTools from '@/components/Staff/StaffTools'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('staff')

export default {
  name: 'ContributorView',
  // -------------
  // Components ==
  // -------------
  components: {
    CreateRoom19AccountDialog,
    DisableStaffDialog,
    PasswordResetDialog,
    StaffDetails,
    StaffTools
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(to, _from, next) {
    next(vm => {
      // Get contact
      const slug = to.params.slug
      vm.fetchContact(slug)
      // Password reset?
      if (to.query.resetPassword) {
        vm.$dialog.open('password-reset')
      }
    })
  },
  async beforeRouteUpdate(to, from, next) {
    const slug = to.params.slug
    if (slug !== from.params.slug) {
      await this.fetchContact(slug)
    }
    next()
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: this.contact.name
    }
  },
  // -------------
  // Properties ==
  // -------------
  props: {
    slug: {
      type: String,
      default: null
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'contact'
    ]),
    ...mapGetters([
      'loading'
    ]),
    contactIcon () {
      if (this.contact.get('deleted_at')) {
        return 'ban'
      } else if (this.$route.name.startsWith('contributor-edit')) {
        return 'edit'
      } else {
        return null
      }
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'fetchContact'
    ])
  }
}
</script>
