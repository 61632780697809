<template>
  <j-card
    title="Staff Page"
    height="230"
  >
    <j-card-text>
      <!-- Avatar -->
      <j-avatar
        :source="contact"
        size="150"
        right
      />

      <!-- Location -->
      <div class="overline mb-4">
        {{ contact.location }}
      </div>

      <!-- Name -->
      <h1 class="display-2 mb-1">
        {{ contact.name }}
      </h1>

      <!-- Tagline -->
      <h3
        v-if="contact.tagline"
        class="headline mb-1"
      >
        {{ contact.tagline }}
      </h3>

      <!-- Full Bio -->
      <j-markdown
        v-if="contact.full_bio"
        :source="contact.full_bio"
        class="mt-4"
      />
    </j-card-text>
  </j-card>
</template>

<script>
export default {
  name: 'StaffPage',
  // -------------
  // Properties ==
  // -------------
  props: {
    contact: {
      type: Object,
      default: null
    }
  }
}
</script>
