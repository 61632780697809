<template>

  <!-- Staff Information -->
  <j-card
    title="Editorial Information"
    class="staff-credit"
  >
    <j-card-text>
      <v-container
        fluid
        class="pa-0"
      >
        <v-row>
          <v-col>

            <!-- Email -->
            <j-display label="Email">
              <a :href="`mailto:${contact.email}`">
                <j-icon
                  icon="envelope"
                  :title="contact.email"
                  class="mr-1"
                />{{ contact.email }}</a>
            </j-display>

          </v-col>
          <v-col>

            <!-- Phone -->
            <j-display label="Phone">
              {{ contact.day_phone }}
            </j-display>

          </v-col>
        </v-row>
        <v-row>
          <v-col>

            <!-- Mailing Address -->
            <j-display label="Mailing Address">
              {{ contact.address }}
              <br v-if="contact.city || contact.state">
              {{ contact.city }}
              <span v-if="contact.state">,&nbsp;</span>
              {{ contact.state }}
              {{ contact.zip }}
            </j-display>
          </v-col>
          <v-col>

            <!-- Contact Notes -->
            <j-display label="Contact Notes">
              {{ contact.contact_notes }}
            </j-display>

          </v-col>
        </v-row>
        <v-row>
          <v-col>

            <!-- Date of Birth -->
            <j-display label="Date of Birth">
              {{ contact.dob }}
            </j-display>
          </v-col>
          <v-col>

            <!-- Gender / Pronouns -->
            <j-display label="Gender / Pronouns">
              {{ contact.gender }}
            </j-display>

          </v-col>
        </v-row>
        <v-row>
          <v-col>

            <!-- Share Address and Phone -->
            <j-display label="Share Address and Phone">
              {{ contact.privacy ? 'Yes' : 'No' }}
            </j-display>

          </v-col>
          <v-col>

            <!-- Newsletter Subscription -->
            <j-display label="Newsletter Subscription">
              {{ contact.newsletter ? 'Yes' : 'No' }}
            </j-display>
          </v-col>
        </v-row>
        <v-row>
          <v-col>

            <!-- Groups -->
            <j-display label="Groups">
              {{ contact.groups.join(' / ') }}
            </j-display>
          </v-col>
          <v-col v-if="contact.room19_username">

            <!-- Room 19 Username -->
            <j-display label="Room 19 Username">
              <a :href="`https://room19.com/@${contact.room19_username}`" target="_blank">
                @{{ contact.room19_username }}
              </a>
            </j-display>
          </v-col>
        </v-row>
      </v-container>
    </j-card-text>
  </j-card>
</template>

<script>

export default {
  name: 'StaffInformation',
  // -------------
  // Properties ==
  // -------------
  props: {
    contact: {
      type: Object,
      default: null
    }
  }
}
</script>
