<template>
  <!-- Modal for updating password  -->
  <j-dialog
    dialog-name="password-reset"
    :value="$dialog.status('password-reset')"
    title="Change Password"
    max-width="50%"
    @cancel="handleCloseDialog"
  >
    <j-card-text>
      <!-- Reset Success -->
      <j-alert
        v-if="passwordUpdated && contact.pristine"
        type="success"
        message="Your password has been updated."
        class="mt-4"
      />

      <j-form v-else model="staff.contact">
        <!-- Password -->
        <j-control
          name="password"
          cols="12"
          sm="6"
        />

        <!-- Password Confirm -->
        <j-control
          name="password_confirm"
          cols="12"
          sm="6"
        />
      </j-form>
    </j-card-text>

    <!-- Actions -->
    <template #actions>
      <!-- Update -->
      <j-button
        v-if="!(passwordUpdated || contact.pristine)"
        label="Update"
        :disabled="contact.pristine"
        :processing="contact.saving"
        @action="handleChangePassword"
      />
      <!-- Close -->
      <j-button
        v-else
        label="Close"
        @action="handleCloseDialog"
      />
    </template>

    <!-- Options -->
    <template #options>
      <!-- Cancel -->
      <j-button
        v-if="!(contact.saving || passwordUpdated || contact.pristine)"
        label="Close"
        type="option"
        @action="handleCloseDialog"
      />
    </template>
  </j-dialog>
</template>

<script>

// Components

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('staff')

export default {
  name: 'PasswordResetDialog',
  data () {
    return {
      passwordUpdated: false
    }
  },
  computed: {
    ...mapState([
      'contact'
    ]),
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'saveContact',
    ]),
    handleCloseDialog () {
      this.passwordUpdated = false
      this.$dialog.close('password-reset')
    },
    handleChangePassword() {
      this.saveContact().then(() => this.passwordUpdated = this.contact.isValid)
    }
  }
}
</script>
